<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row type="flex" :gutter="24">
        <a-col :span="24" :lg="24" class="mb-24">
          <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
            <template #title>
              <h5 class="mb-0">Category details</h5>
            </template>
            <a-row :gutter="[12]">
              <a-col :span="6">
                <a-form-item class="mb-10" label="Name" :colon="false">
                  <a-input v-model="name" placeholder="eg. Something" />
                </a-form-item>
              </a-col>
              <a-col v-if="isEdit" :span="6">
                <a-form-item class="mb-10" label="Status" :colon="false">
                  <a-switch v-model="is_active" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-button type="primary" html-type="submit"> Submit </a-button>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import categoryApi from '../../api/category';
import router from '../../router';

export default {
  data() {
    return {
      name: '',
      categoryEditData: null,
      isEdit: false,
      is_active: false,
      form: this.$form.createForm(this, { name: "editCategory" }),
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, category) => {
        if (!err) {
          category.name = this.name;
          category.is_active = this.is_active;
          if (!this.isEdit) {
            categoryApi.createCategory(category).then((response) => {
              if (response.status == 201) {
                router.push('/categories/categories-list');
              }
            }).catch((error) => {
              console.log(error);
              router.push('/categories/categories-list');
            })
          } else {
            categoryApi.updateCategory(this.categoryEditData.category.id, category).then((response) => {
              if (response.status == 200) {
                router.push('/categories/categories-list')
              }
            }).catch((error) => {
              console.log(error);
              router.push('/categories/categories-list');
            })
          }
        }
      });
    },
    async getCategoryById(id) {
      return categoryApi.getCategory(id);
    }
  },
  async mounted() {
    if (this.$route.params.id !== undefined) {
      this.categoryEditData = await this.getCategoryById(this.$route.params.id); // get city name by id to edit it
      this.isEdit = true;
      this.is_active = this.categoryEditData.category.is_active;
      this.name = this.categoryEditData.category.name;
    }
  },
};
</script>
